<template>
    <div id="course-management">
        <leftbar-component></leftbar-component>
        <div class="content">
            <topbar-component :items="items"></topbar-component>
            <h1>课程管理</h1>
            <div class="search-bar">
                <div class="lf">
                    <div class="course-entrybox">
                        <i class="icon-search"></i>
                        <span>课程</span>
                        <input type="text" v-model="className">
                    </div>
                    <div class="calendar">
                        <el-date-picker
                            popper-class="popDateBox"
                            v-model="searchDate"
                            type="daterange"
                            range-separator="~"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期">
                        </el-date-picker>
                    </div>
                    <div class="btn-search" @click="search">查询</div>
                </div>
                <div class="rf" @click="release">新增课程</div>
            </div>
            <div class="table">
                <el-table
                        :data="workList"
                        style="width: 100%">
                    <el-table-column
                            prop="orderId"
                            label="订单号">
                    </el-table-column>
                    <el-table-column
                            prop="price"
                            align="center"
                            width="100"
                            label="课件价格">
                    </el-table-column>
                    <el-table-column
                            prop="courseName"
                            align="center"
                            width="100"
                            label="所属课程">
                    </el-table-column>
                    <el-table-column
                            prop="buyId"
                            align="center"
                            width="100"
                            label="下单账号">
                    </el-table-column>
                    <el-table-column
                            prop="buyName"
                            align="center"
                            width="100"
                            label="下单姓名">
                    </el-table-column>
                    <el-table-column
                            prop="createTime"
                            align="center"
                            label="下单时间">
                        <template slot-scope="scope">
                            {{ scope.row.createTime | formatDate }}
                        </template>
                    </el-table-column>
                    <el-table-column
                            prop="status"
                            align="center"
                            width="120"
                            label="订单状态">
                        <template slot-scope="scope" class="table-status">
                            <span :style="{'color': scope.row.status === 1 ? '#1DC084' : scope.row.status === 2 ? '#DC000C' : '#666666'}"
                                  :class="scope.row.status === 2 ? 'off-shelf' : ''">{{ scope.row.status === 0 ? '审核中' : scope.row.status === 1 ? '上架中' : "已下架" }}</span>
                            <div class="off-shelf-reason" v-if="scope.row.status === 2">审核不通过</div>
                        </template>
                    </el-table-column>
                </el-table>
                <listH5Component
                    :list="workList"
                >
                    <template v-slot:title="rowData" >
                        {{rowData.prop.name}}
                    </template>
                    <template v-slot:datetime="rowData" >
                        {{rowData.prop.orderTime | formatDateTime}}
                    </template>
                    <template v-slot:status="rowData" >
                        <span 
                            :style="{'color': rowData.prop.status === 1 ? '#1DC084' : rowData.prop.status === 2 ? '#DC000C' : '#666666','text-decoration':rowData.prop.status === 2 ? 'underline' : 'none'}"
                            :class="rowData.prop.status === 2 ? 'off-shelf' : ''">{{ rowData.prop.status === 0 ? '审核中' : rowData.prop.status === 1 ? '上架中' : "已下架" }}
                        </span>
                    </template>
                    
                </listH5Component>
                <div class="pagination" v-if="total > 10">
                    <el-pagination
                        background
                        layout="prev, pager, next"
                        :current-page.sync="page"
                        :page-size="pageSize"
                        :total="total">
                    </el-pagination>
                </div>
            </div>

            <el-dialog
                :title="editingType === editingButtons[2] ? '课件详情' : editingType === editingButtons[1] ? '课件绑定' : editing ? '编辑课程' : '新增课程'"
                width="35%"
                :visible.sync="dialogVisible"
                :close-on-click-modal="false"
                class="dialog"
            >
                <!-- 编辑 -->
                <el-form :model="form" v-if="editingType === editingButtons[0]">
                    <el-form-item label="课程名称" :label-width="formLabelWidth">
                        <el-input v-model="form.name" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="课程描述" :label-width="formLabelWidth">
                        <el-input type="textarea" v-model="form.desc"></el-input>
                    </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer" v-if="editingType === 'EDIT'">
                    <el-button type="primary" @click="dialogVisible = false">{{ editing ? '保存' : '新增' }}</el-button>
                </div>

                <!-- 课件 -->
                <div class="courseware" v-if="editingType === editingButtons[1]">
                    <div class="binding-course">
                        <div class="course">课程名称：<span>机器人教育初级课程</span></div>
                        <div class="courseware-form">
                            <div class="search-bar">
                                <span>搜 索</span>
                                <el-input v-model="courseware" placeholder="请输入课件名称"></el-input>
                                <el-select v-model="coursewareType">
                                    <el-option
                                        v-for="item in options"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value">
                                    </el-option>
                                </el-select>
                            </div>
                            <el-table
                                ref="mutipleTable"
                                :data="coursewareList"
                                tooltip-effect="dark"
                                style="width: 100%"
                                @selection-change="chooseCourseware"
                            >
                                <el-table-column
                                    type="selection"
                                    width="55"
                                >
                                </el-table-column>
                                <el-table-column
                                    label="名称"
                                    prop="name"
                                >
                                </el-table-column>
                                <el-table-column
                                    label="类型"
                                    prop="type"
                                    width="80"
                                >
                                </el-table-column>
                            </el-table>
                            <div class="courseware-pagination">
                                <span>已选{{ selectedList.length }}个</span>
                                <el-pagination
                                    small
                                    layout="prev, pager, next"
                                    @current-change="changeCoursewarePage"
                                    :current-page.sync="coursewarePage"
                                    :page-size="coursewarePagesize"
                                    :total="coursewareTotal"
                                ></el-pagination>
                            </div>
                        </div>
                        <div class="btn">
                            <el-button type="primary">新增</el-button>
                        </div>
                    </div>
                </div>

                <!-- 详情 -->
                <div class="course-detail" v-if="editingType === editingButtons[2]">
                    <ul>
                        <li>
                            <label>课程名称</label>
                            <div>机器人教育初级课程</div>
                        </li>
                        <li>
                            <label>新增时间</label>
                            <div>2020-12-04</div>
                        </li>
                        <li>
                            <label>课程描述</label>
                            <div>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Blanditiis cupiditate, delectus dicta saepe sed soluta suscipit. A aperiam aspernatur debitis eius, incidunt maiores maxime nesciunt nisi officiis, quia quis, quos.</div>
                        </li>
                        <li>
                            <label>课件资源</label>
                            <div>
                                <el-table
                                    border
                                    :data="coursewareList"
                                    tooltip-effect="dark"
                                    style="width: 100%"
                                >
                                    <el-table-column
                                        label="名称"
                                        prop="name"
                                    >
                                    </el-table-column>
                                    <el-table-column
                                        label="课件"
                                        prop="filename"
                                    >
                                    </el-table-column>
                                    <el-table-column
                                        label="操作"
                                        width="60"
                                    >
                                        <template slot-scope="scope">
                                            <span style="color: #409EFF; cursor: pointer;" @click="filePreview(scope.$index, scope.row)">预览</span>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </div>
                        </li>
                        <li>
                            <label>学生列表</label>
                            <div>
                                <el-table
                                    border
                                    :data="students"
                                    tooltip-effect="dark"
                                    style="width: 100%"
                                >
                                    <el-table-column
                                        label="序号"
                                        type="index"
                                        width="55"
                                    >
                                    </el-table-column>
                                    <el-table-column
                                        label="学生账号"
                                        prop="account"
                                    >
                                    </el-table-column>
                                    <el-table-column
                                        label="姓名"
                                        prop="name"
                                    >
                                    </el-table-column>
                                </el-table>
                            </div>
                        </li>
                    </ul>
                </div>
            </el-dialog>
        </div>
    </div>
</template>

<script>
    import LeftbarComponent from './component/leftbarComponent'
    import TopbarComponent from './component/topbarComponent'
    import listH5Component from './component/listH5Component'
    import { urls, banbaoUrl } from '@/lib/common'
    export default {
        name: "StudentOrders",
        components: {
            LeftbarComponent,
            TopbarComponent,
            listH5Component
        },
        data() {
            return {
                banbaoUrl,
                pageCurrent: 1,
                pageSize: 10,
                total: 0,
                items: [
                    {
                        text: '个人中心',
                        disabled: false,
                        href: '/userCenter',
                    }, {
                        text: '学生订单',
                        disabled: true,
                        href: '/studentOrders',
                    }
                ],
                workList: [],
                className: '',
                searchDate: '',
                token: '',
                userinfo: '',
                dialogVisible: false,
                editingButtons: ['EDIT', 'COURSEWARE', 'DETAIL'],
                editing: false,
                editingType: '',
                formLabelWidth: '120px',
                form: {
                    name: '',
                    desc: ''
                },
                options: [
                    {
                        value: '',
                        label: '选择'
                    }, {
                        value: 'file',
                        label: '文档'
                    }, {
                        value: 'video',
                        label: '视频'
                    }, {
                        value: 'audio',
                        label: '音频'
                    }
                ],
                courseware: '',
                coursewareType: '',
                coursewareList: [
                    {
                        id: 1,
                        name: '机器人教育初级学习手册1',
                        filename: '附件1',
                        type: '文档'
                    }, {
                        id: 2,
                        name: '【第一课】游戏的定义',
                        filename: '附件2',
                        type: '视频'
                    }, {
                        id: 3,
                        name: '机器人教育初级学习手册2',
                        filename: '附件3',
                        type: '文档'
                    }, {
                        id: 4,
                        name: '机器人教育初级学习手册3',
                        filename: '附件4',
                        type: '音频'
                    }
                ],
                selectedList: [],
                coursewarePagesize: 5, // 绑定课程列表显示列表长度
                coursewarePage: 0, // 绑定课程列表页码
                coursewareTotal: 50, // 绑定课程列表总数
                students: [ // 学生列表
                    {
                        id: 1,
                        account: 'zhanghao1',
                        name: '李彤'
                    }, {
                        id: 2,
                        account: 'zhanghao2',
                        name: '张三'
                    }, {
                        id: 3,
                        account: 'zhanghao3',
                        name: '李四'
                    }, {
                        id: 4,
                        account: 'zhanghao4',
                        name: '王五'
                    }, {
                        id: 5,
                        account: 'zhanghao5',
                        name: '二六'
                    }
                ],

            }
        },
        methods: {
            dateFormat(date) {
                const y = date.getFullYear();
                const m = date.getMonth() + 1;
                const d = date.getDate();
                const month = m >= 10 ? m : '0' + m;
                const day = d >= 10 ? d : '0' + d;
                return `${y}-${month}-${day}`
            },
            search() { // 查询
                // console.log('search')
                this.getOrderList();
            },
            handleEdit(index, row) {
                console.log('handleEdit', index, row)
                this.editingType = this.editingButtons[0];
                this.editing = true;
                this.form.name = row.name;
                this.form.desc = row.desc;
                this.dialogVisible = true;
            },
            handleCheckCourseware(index, row) {
                console.log('handleCheckCourseware', index, row)
                this.editingType = this.editingButtons[1];
                this.dialogVisible = true;

            },
            handleDetail(index, row) {
                console.log('handleDetail', index, row)
                this.editingType = this.editingButtons[2];
                this.dialogVisible = true;

            },
            handleDelete(index, row) {
                console.log('handleDelete', index, row)

                this.workList.splice(index, 1)
            },
            release() {
                console.log('release');
                this.form.name = '';
                this.form.desc = '';
                this.editing = false;
                this.editingType = this.editingButtons[0];
                this.dialogVisible = true;
            },
            chooseCourseware(e) {
                console.log('chooseCourseware', e);
                this.selectedList = e;
            },
            changeCoursewarePage() {
                console.log('changeCoursewarePage', this.coursewarePage)
            },
            filePreview(index, row) { // 文件预览
                console.log('filePreview', index, row);
            },
            getOrderList() { // 获取订单列表
                const params = {
                    pageCurrent: this.pageCurrent,
                    pageSize: this.pageSize,
                    teacherId: this.userinfo.userId
                }
                if (this.searchDate) {
                    const start = this.searchDate[0];
                    const end = this.searchDate[1];
                    const startDate = `${this.dateFormat(start)} 00:00:00`;
                    const endDate = `${this.dateFormat(end)} 23:59:59`;
                    params.startDate = startDate;
                    params.endDate = endDate;
                }
                this.$http.get(urls.getOrderList, {params}).then(res => {
                    // console.log(res)
                    if (res.data.status === 200) {
                        this.workList = res.data.data.records;
                        this.total = res.data.data.total;
                    } else if (res.data.status !== 401 && res.data.status !== 106) {
                        this.$message.error(res.data.message)
                    }
                })
            }
        },
        created() {
            const token = this.$cookies.get('app.token', {domain: banbaoUrl, path: ''});
            const userinfo = JSON.parse(sessionStorage.getItem("userinfo"));
            if (token) this.token = token;
            if (userinfo) {
                this.userinfo = userinfo;
            } else if (this.$cookies.get('app.uid', {domain: banbaoUrl, path: ''})) {
                this.userinfo = {
                    userId: this.$cookies.get('app.uid', {domain: banbaoUrl, path: ''})
                }
            }
            this.getOrderList()
        }
    }
</script>

<style lang="less" scoped>
    @deep: ~'>>>';
    #course-management {
        display: flex;
        justify-content: flex-start;
        .content {
            width: 84%;
            box-sizing: border-box;
            background: #F2FCFF;
            padding-bottom: 5%;

            >h1 {
                font-size: 1.25vw;
                margin: 1vw 0 1vw 2vw;
            }
            >.search-bar {
                margin: 1vw 0 1vw 2vw;
                width: 90%;
                box-sizing: border-box;
                display: flex;
                justify-content: space-between;
                align-items: center;
                font-size: 0.73vw;
                .lf {
                    display: flex;
                    align-items: center;
                    .course-entrybox {
                        background-color: #fff;
                        margin-right: 10px;
                        line-height: 40px;
                        border-radius: 4vw;
                        box-sizing: border-box;
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        color: #666;
                        .icon-search {
                            display: inline-block;
                            width: 16px;
                            height: 16px;
                            background: url("../../assets/images/user-center/icon_search_dark.png") no-repeat center center;
                            background-size: 100%;
                            margin-left: 10px;
                        }
                        span {
                            display: inline-block;
                            margin-left: 10px;
                            padding-right: 10px;
                            border-right: 1px solid #eaeaea;
                        }
                        input {
                            width: 10vw;
                            padding: 0 15px 0 10px;
                        }
                    }
                    .calendar {
                        margin-right: 10px;
                        @{deep} .el-date-editor {
                            border: 0;
                            border-radius: 100px;
                            width: 240px;
                        }
                    }
                    .btn-search {
                        background-color: #24CEFF;
                        color: #fff;
                        line-height: 40px;
                        padding: 0 1.5vw;
                        cursor: pointer;
                        border-radius: 100px;
                        transition: all .3s linear;
                        &:hover {
                            background-color: #129DC6;
                        }
                    }
                }
                .rf {
                    background: #1AAB7D;
                    color: #fff;
                    line-height: 40px;
                    padding: 0 2vw;
                    border-radius: 4vw;
                    cursor: pointer;
                    transition: all .3s linear;
                    &:hover {
                        background-color: #157C5D;
                        box-shadow: 0 2px 5px 1px rgba(60,60,60,.5);
                    }
                }
            }
            .table {
                width: 90%;
                margin: 1vw 0 1vw 2vw;
                @{deep} .el-table {
                    overflow: visible;
                    &::before {
                        width: 0;
                        height: 0;
                    }
                    thead {
                        th {
                            background-color: #24CEFF;
                            color: #fff;
                        }
                        th:first-child {
                            border-radius: 10px 0 0 0;
                        }
                        th:nth-child(7) {
                            border-radius: 0 10px 0 0;
                        }
                    }
                    .el-table__body-wrapper {
                        overflow: visible;
                        tbody {
                            .el-table__row:last-child {
                                border-radius: 0 0 10px 10px;
                                border-bottom: 0;
                                td:first-child {
                                    border-radius: 0 0 0 10px;
                                }
                                td:last-child {
                                    border-radius: 0 0 10px 0;
                                }
                            }
                            .el-table__row {
                                .cell {
                                    position: relative;
                                    overflow: visible;
                                    .off-shelf {
                                        cursor: pointer;
                                        text-decoration: underline;
                                    }
                                    .off-shelf-reason {
                                        display: none;
                                        position: absolute;
                                        top: 50%;
                                        left: 50%;
                                        background: #fff;
                                        padding: 6px;
                                        border-radius: 4px;
                                        box-shadow: 0 0 5px 1px #aaa;
                                        width: 200px;
                                        text-align: left;
                                        z-index: 999;
                                    }
                                    &:hover {
                                        .off-shelf-reason {
                                            display: block;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                .pagination {
                    text-align: right;
                    margin: 1vw 0 0 0;
                    @{deep} {
                        .el-pagination {
                            .el-pager li {
                                background-color: #fff;
                                &.active {
                                    background-color: #24CEFF;
                                }
                            }
                            .btn-next,
                            .btn-prev {
                                background-color: #fff;
                            }
                        }
                    }
                }
            }
            .dialog {
                @{deep} .dialog-footer {
                    text-align: center;
                }
                .courseware {
                    .course {
                        color: #333;
                        span {
                            color: #777;
                        }
                    }
                    .courseware-form {
                        padding: 1vw;
                        border-radius: 4px;
                        border: 1px solid #ddd;
                        margin-top: 1vw;
                        .search-bar {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            >span {
                                display: inline-block;
                                min-width: 4em;
                                margin-right: 10px;
                                font-size: 0.83vw;
                                color: #333;
                                font-weight: bold;
                            }
                            .el-input{
                                margin-right: 1vw;
                            }
                            .el-select {
                                margin-right: 4vw;
                            }
                        }
                        .courseware-pagination {
                            margin-top: 1vw;
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                        }
                        @{deep} .el-table {
                            margin-top: 1vw;
                            th {
                                background-color: #409EFF;
                                color: #fff;
                            }
                            .el-checkbox__input.is-checked .el-checkbox__inner {
                                background-color: #fff;
                                &::after {
                                    border-color: #409EFF;
                                }
                            }
                        }
                    }
                    .btn {
                        margin-top: 1.5vw;
                        text-align: center;
                    }
                }
                .course-detail {
                    @{deep} .el-table {
                        th {
                            background-color: #409EFF;
                            color: #fff;
                        }
                        .el-checkbox__input.is-checked .el-checkbox__inner {
                            background-color: #fff;
                            &::after {
                                border-color: #409EFF;
                            }
                        }
                    }
                    >ul {
                        >li {
                            margin-bottom: 20px;
                            color: #606060;
                            label {
                                float: left;
                                min-width: 6em;
                            }
                            div {
                                float: left;
                                width: calc(100% - 6em);
                            }
                            &::after {
                                content: '';
                                display: block;
                                clear: both;
                            }
                        }
                    }
                }
            }
        }
    }

    .fontFn (@px, @attr: font-size) {
        @rem: (@px / 37.5);
        @{attr}: ~"@{rem}rem";
    }

    @media screen and (max-width: 750px) {
        #course-management {
            flex-direction: column;
            background: #F2FCFF;
            .content{
                width: 100%;
                margin-top:-40%;
                background: none;
                .topbar{
                    display: none;
                }
                &>h1{
                    background: #fff;
                    width: 94.7%;
                    margin:0 auto;
                    .fontFn(10,border-top-left-radius);
                    .fontFn(10,border-top-right-radius);
                    padding: 0 30px;
                    color:#34254C;
                    font-weight: bold;
                    .fontFn(18);
                    .fontFn(48,height);
                    .fontFn(48,line-height);
                }
                &>.search-bar{
                    background: #fff;
                    flex-direction: column;
                    .fontFn(10,border-bottom-left-radius);
                    .fontFn(10,border-bottom-right-radius);
                    width: 94.7%;
                    margin:0 auto;
                    padding:0 26px 3%;
                    margin-bottom: 5%;
                    box-shadow: 0px 5px 10px #D5F5FF;
                    .lf{
                        flex-direction: column;
                        width: 100%;
                        .course-entrybox{
                            .fontFn(20,border-radius);
                            width: 100%;
                            background: #F3F3F3;
                            margin-right: 0;
                            margin-bottom: 5%;
                            .fontFn(38,line-height);
                            .icon-search{
                                .fontFn(18,width);
                                .fontFn(18,height);
                                margin-left: 16px;
                            }
                            span{
                                color:#555859;
                                .fontFn(14);
                                .fontFn(26,line-height);
                            }
                            input{
                                width: auto;
                                flex-grow:1;
                                .fontFn(14);
                            }
                        }
                        .course-status-options{
                            width: 100%;
                            margin-bottom: 5%;

                            @{deep} .el-select{
                                width: 100%;
                            }

                            @{deep} .el-input__inner{
                                padding: 0 32px 0 16px;
                                .fontFn(14);
                                .fontFn(20,border-radius);
                                border:none;
                                background: #F3F3F3;
                                .fontFn(38,height);
                                .fontFn(38,line-height);
                            }
                        }
                        .calendar{
                            width: 100%;
                            margin:0 auto 5%;
                            .el-date-editor{
                                padding: 0;
                                width: 100%;
                                background: #F3F3F3;
                                .fontFn(20,border-radius);
                                @{deep} .el-range__icon{
                                    .fontFn(18);
                                    height: 32px;
                                    margin-left: 16px;
                                }
                                @{deep} .el-range-separator{
                                    height: 10px;
                                    line-height: 10px;
                                }
                                @{deep} .el-range-input{
                                    .fontFn(38,line-height);
                                    .fontFn(14);
                                }
                            }
                        }
                        .btn-search{
                            width: 100%;
                            text-align: center;
                            .fontFn(14);
                            .fontFn(38,height);
                            .fontFn(38,line-height);
                            margin-bottom: 5%;
                        }
                    }
                    .rf{
                        width: 100%;
                        text-align: center;
                        .fontFn(14);
                        .fontFn(38,height);
                        .fontFn(38,line-height);
                        margin-bottom: 5%;
                    }
                }

                .table{
                    .el-table{
                        display: none;
                    }
                    width:94.7%;
                    margin:0 auto;
                }

                .el-dialog__wrapper{
                    @{deep} .el-dialog{
                        width: 96% !important;
                    }

                    @{deep} .el-dialog__body{
                        padding:20px 10px;
                    }

                    @{deep} .el-form-item__label{
                        width: 80px !important;
                    }

                    @{deep} .el-form-item__content{
                        margin-left:80px !important;
                    }

                    @{deep} .el-textarea__inner{
                        min-height: 120px !important;
                    }

                    @{deep} .el-row{
                        img{
                            width: 320px !important;
                            display: block;
                            margin:0 auto;
                        }
                    }
                }

                .dialog{
                    .courseware{
                        label{margin-bottom:3%;}
                        .courseware-form{
                            .search-bar{
                                flex-direction: column;
                                &>span{
                                    display: none;
                                }
                                .el-input{
                                    margin-bottom: 3%;
                                }
                                .el-range-editor.el-input__inner{
                                    width: 100% !important;
                                    margin:0 auto 3% !important;
                                }
                                .el-button{
                                    width: 100%;
                                    margin-bottom: 3%;
                                }
                            }
                        }
                    }
                }
            }
            
        }
    }
</style>

<style lang="less">
@deep: ~'>>>';
@media screen and (max-width: 750px) {
    .el-dialog__wrapper{
        .el-dialog{
            width: 96% !important;
        }

        .vue-cropper{
            width: 100% !important;
        }

        .cropper-crop-box{
            transform: translate3d(0,0,0) !important;
        }
    }

    .el-message-box{
        width: 96%;
    }

    .popDateBox{
        width: auto !important;
        top:2% !important;
        left:2% !important;
        right:2% !important;
        margin:0 !important;
        background:rgba(0,0,0,0.5);

        .popper__arrow{
            display: none;
        }

        .el-picker-panel__body{

            min-width: auto !important;
            background: #fff !important;
        }

        .el-date-range-picker__content{
            float:none !important;
            width: 100% !important;
        }
    }
}
</style>